import { CHAIN_IDS } from 'utils/wagmi'
// import Swap from '../views/Swap'
import Home from '../views/Home'

const SwapPage = () => {
  return <Home />
}

SwapPage.chains = CHAIN_IDS

export default SwapPage
